import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from "@angular/core";
import { NgxPopperjsDirective, NgxPopperjsModule, NgxPopperjsPlacements, NgxPopperjsTriggers } from "ngx-popperjs";
import { UICommandInterface } from "../../../../../view-models/commands/ui-command.interface";
import { MaskedNumber } from 'imask';
import { IMaskDirective } from "angular-imask";
import { BaseNumericPropertyViewModel } from '../../../../../view-models/base-type/base-numeric-property-view-model';
import { FormsModule } from "@angular/forms";
import { AsyncPipe, NgClass, NgFor, NgIf } from "@angular/common";
import { RibbonButtonComponent } from "../../../../shared/buttons/ribbon-button/ribbon-button.component";
import { PopperHelper } from "@nts/std/utility";
import { BehaviorSubject } from "rxjs";

// import _Inputmask from 'inputmask';
// const InputmaskConstructor = (_Inputmask as any).default || _Inputmask;
export interface NumericMaskSettings {
    useThousandSeparator?: boolean,
    decimalLimit?: number;
    integerLimit?: number;
    min?: number;
    max?: number;
    nullable: boolean;
}

export class NtsMaskedNumber extends MaskedNumber {

    nullable = false;
    _value = null;
    element: ElementRef;

    constructor(opts: any) {
        super(opts)
        this.nullable = opts.nullable;
    }

    override doPrepare (str: string, flags: any): any {
        if (this.nullable === false && str === '') {
            str = '0';
        }
        const doPrepareResult = super.doPrepare(str, flags) as [string, any];
        if (str === this.thousandsSeparator && this.mapToRadix.indexOf(this.thousandsSeparator) > -1 ) {
            if (this.element?.nativeElement?.value?.indexOf(this.radix) > -1) {
                doPrepareResult[0] = this.thousandsSeparator;
            }
        }
        return doPrepareResult;
    }

    override doCommit () {

        const res = super.doCommit()

        if (this.nullable === false && this.value === '') {
            this.value = '0';
        }
        return res;
    }

    get typedNullableValue() {
        if (this.nullable === true && this.value === '') {
            return null;
        }
        return this.typedValue;
    }

    // set typedNullableValue(val) {
    //     if (!(this.nullable === true && this.value === '')) {
    //         this.typedValue = val;
    //     }
    // }
}
@Component({
    selector: 'nts-base-numeric-box',
    templateUrl: './base-numeric-box.component.html',
    styleUrls: ['./base-numeric-box.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgxPopperjsModule,
        IMaskDirective,
        FormsModule,
        NgClass,
        RibbonButtonComponent,
        NgIf,
        AsyncPipe,
        NgFor
    ]
})
export class BaseNumericBoxComponent implements AfterViewInit, OnChanges, OnInit, OnDestroy {

    @Input() minValue;
    @Input() maxValue;
    @Input() maskSettings: NumericMaskSettings;
    @Input() inputMode = null;
    @Input() tabIndex = -1;
    @Input() isDisabled = false;
    @Input() value: number | string = null;
    @Input() isReadonly = false;
    @Input() placeholder = "";
    @Input() customClasses = "";
    @Input() errorList: string[] = [];
    @Input() showErrorTooltip = true;
    @Input() showErrorBorder = true;
    @Input() primaryColor = null;
    @Input() customCommandList: UICommandInterface[] = [];
    @Input() defaultBorderColor = null;
    @Input() activeBorderColor = null;
    @Input() hoverBorderColor = null;
    @Input() listenClickOutside = false;

    @Output() onFinishEditing = new EventEmitter();
    @Output() onBlur = new EventEmitter();
    @Output() onFocus = new EventEmitter();
    @Output() onFocusOut = new EventEmitter();
    @Output() onKeyDown = new EventEmitter();
    @Output() valueChange = new EventEmitter();
    @Output() inputChange = new EventEmitter();

    @ViewChild('numericBox', { static: true }) numericBox: ElementRef;
    @ViewChild(NgxPopperjsDirective, { static: true }) popperError: NgxPopperjsDirective;
    @ViewChild(IMaskDirective, { static: true }) iMask: IMaskDirective<any>;

    separatorLimit: string = '';
    nullable = true;
    currentMask = 'separator';
    currentValue : string = "";
    ngxPopperjsTriggers = NgxPopperjsTriggers;
    ngxPopperjsPlacements = NgxPopperjsPlacements;
    static thousandSeparator = BaseNumericPropertyViewModel.getThousandSeparator();
    static decimalSeparator = BaseNumericPropertyViewModel.getDecimalSeparator();
    mask = null;
    overrideBorderColor = null;
    isActive = false;
    isHover = false;
    documentClickListener: any;
    computedInputMode$ = new BehaviorSubject<null|string>('');

    constructor(
        private readonly cd: ChangeDetectorRef,
        private readonly zone: NgZone,
        public readonly el: ElementRef,
        private readonly renderer: Renderer2,
    ) {

    }

    ngAfterViewInit(): void {

    }

    ngOnInit(): void {
        this.updateInputMode();

        this.handleOverridesColors();

        if (this.listenClickOutside) {
            this.bindDocumentClickListener();
        }
    }

    updateInputMode() {
      this.computedInputMode$.next(this.inputMode ?? ((this.maskSettings?.decimalLimit != null && this.maskSettings?.decimalLimit > 0) ? 'decimal' : 'numeric'))
    }

    ngOnDestroy() {
        this.unbindDocumentClickListener();
    }

    unbindDocumentClickListener() {
        if (this.documentClickListener) {
            this.documentClickListener();
            this.documentClickListener = null;
        }
    }

    isOutsideClicked(event: Event) {
        return !(this.el.nativeElement.isSameNode(event.target) || this.el.nativeElement.contains(event.target));
    }

    initMaskSettings(maskSettings: NumericMaskSettings): void {
        if (maskSettings) {
            const mask = new NtsMaskedNumber(BaseNumericBoxComponent.getMaskedNumberOptions(maskSettings, this.numericBox));
            this.mask = { mask };
        }
    }

    static getMaskedValue(maskSettings: NumericMaskSettings, value: number) {
        if (maskSettings) {
            const mask = new NtsMaskedNumber(BaseNumericBoxComponent.getMaskedNumberOptions(maskSettings));
            return parseFloat(mask.resolve(value.toString()))
        }
        return value;
    }

    static getMax(maskSettings: NumericMaskSettings) {
        let max = null;
        let maxString = '';
        if (maskSettings.integerLimit > 0) {
            for (let i = 0; i < maskSettings.integerLimit; i++) {
                maxString += '9';
            }
            max = parseFloat(maxString);
        }

        if (maskSettings.max != null) {
            max = (max != null) ?
                (maskSettings.max > max ? max : maskSettings.max) :
                 maskSettings.max;
        }
        return max;
    }

    static getMaskedNumberOptions(maskSettings: NumericMaskSettings, numericBox?: ElementRef) {
        return {
            mask: Number,
            scale: maskSettings.decimalLimit ? maskSettings.decimalLimit : null,
            signed: true,
            padFractionalZeros: true,
            normalizeZeros: true,
            overwrite: 'shift',
            max: this.getMax(maskSettings),
            min: maskSettings?.min,
            thousandsSeparator: maskSettings.useThousandSeparator ? this.thousandSeparator : '' ,
            radix: this.decimalSeparator,
            mapToRadix: [this.thousandSeparator],
            nullable: maskSettings.nullable,
            element: numericBox
        };
    }

    applyMaskLogic(val: number|string): number|string {
        if(this.maskSettings){
            if(val || !this.maskSettings.nullable){
                if (typeof val == 'number') {
                    val = val.toString().replace('.', BaseNumericBoxComponent.decimalSeparator);
                }
            }
        }
        return val;
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (changes['defaultBorderColor'] || changes['activeBorderColor'] || changes['hoverBorderColor']) {
            this.handleOverridesColors();
        }

        if (changes['maskSettings'] && changes['maskSettings'].currentValue){
            this.initMaskSettings(this.maskSettings);
            this.value = this.applyMaskLogic(this.value)
            this.updateInputMode();
        }

        if (changes['value']) {
            let value = changes['value'].currentValue == null ? '' : changes['value'].currentValue
            this.value = this.applyMaskLogic(value)
        }

        if (changes['errorList']) {
            this.checkPopper();
        }
    }

    handleOverridesColors() {
        if (!this.defaultBorderColor || !this.activeBorderColor || !this.hoverBorderColor) {
            // devono essere impostate tutte e tre le variabili
            return;
        }

        this.overrideBorderColor = this.defaultBorderColor;
        if (this.isActive && !this.isDisabled) {
            this.overrideBorderColor = this.activeBorderColor;
        }
        if (this.isHover && !this.isDisabled) {
            this.overrideBorderColor = this.hoverBorderColor;
        }
    }

    blur(e): void {
        this.isActive = false;
        this.handleOverridesColors();
        this.popperError?.hide();
        this.onBlur.emit(e);
    }

    focus(e): void {
        this.isActive = true;
        this.handleOverridesColors();
        this.onFocus.emit(e);
    }

    onInputChange(value): void {
        this.inputChange.emit(value);
    }

    onModelChange(value: any): void {
        // NON MODIFICARE onModelChange IN INPUT, altrimenti il mask non funziona correttamente
        let stringedValue = this.numericBox.nativeElement.value === undefined ? null : this.numericBox.nativeElement.value;
        if (this.maskSettings?.nullable === true && stringedValue === '') {
            value = null;
        }
        this.valueChange.emit(value);
    }

    mouseEnter(e) {
        this.isHover = true;
        this.handleOverridesColors();
    }

    mouseLeave(e) {
        this.isHover = false;
        this.handleOverridesColors();
    }

    bindDocumentClickListener() {
        if (!this.documentClickListener) {
            this.zone.runOutsideAngular(() => {
                const documentTarget: any = this.el ? this.el.nativeElement.ownerDocument : 'document';

                this.documentClickListener = this.renderer.listen(documentTarget, 'click', (event) => {
                    if (this.isOutsideClicked(event)) {
                        this.zone.run(() => {
                            this.onFinishEditing.emit();

                            this.cd.markForCheck();
                        });
                    }

                });
            });
        }
    }

    private checkPopper() {
        if (this.popperError && (this.errorList == null || this.errorList.length === 0)) {
            PopperHelper.hide(this.popperError);
        }
        // if (this.popperInfo && this.errorList?.length > 0) {
        //     PopperHelper.hide(this.popperInfo);
        // }
    }
}
